<template>
  <div class="file-uploader">
    <div
      v-if="!file && !editedGuide?.editedFileName"
      @click="triggerFileInput"
    >
      <div class="file-uploader__button-upload">
        <SvgUploadFile />
        <span>Upload pdf file</span>
      </div>
    </div>

    <div
      v-else
      class="file-uploader__info"
      :class="{ 'file-uploader__info--error' : errors }"
    >
      <div
        v-if="file || editedGuide?.editedFileName"
        class="file-uploader__info-loading"
      >
        <!-- <div
          v-if="!isUploaded"
          class="file-uploader__info-loading-circle"
        >
          <svg
            height="16"
            width="16"
          >
            <path
              d="M 8 0.5 A 7.5 7.5 0 1 1 7.5 0.5"
              fill="none"
              stroke-width="1"
            />
          </svg>
        </div> -->
        <div class="file-uploader__info-done">
          <SvgUploadDone />
        </div>
      </div>
      <div class="file-uploader__info-name file-uploader__info-name--done">
        {{ file?.name || editedGuide?.editedFileName }}
      </div>
      <div v-if="file?.size || editedGuide?.editedFileSize" class="file-uploader__info-size">
        {{ formatSize(file?.size || editedGuide?.editedFileSize) }}
      </div>
      <!-- <button
        v-if="!isUploaded"
        class="file-uploader__button-cancel"
        @click="resetFile"
      >
        <SvgCancelUpload />
      </button> -->
      <div
        v-if="file || editedGuide?.editedFileName"
        class="file-uploader__button-reload"
        @click="triggerFileInput"
      >
        <SvgReloadFile />
      </div>
    </div>

    <input
      ref="fileInput"
      accept="application/pdf"
      class="file-uploader__file-input"
      type="file"
      @change="updateFile($event.target.files[0])"
    >
  </div>
</template>

<script>
import SvgUploadFile from '@/pages/guide-manager/svg/upload-file.vue'
import SvgUploadDone from '@/pages/guide-manager/svg/upload-done.vue'
// import SvgCancelUpload from '@/pages/guide-manager/svg/cancel-upload.vue'
import SvgReloadFile from '@/pages/guide-manager/svg/reload-file.vue'

export default {
  components: {
    SvgUploadFile,
    // SvgCancelUpload,
    SvgReloadFile,
    SvgUploadDone,
  },
  props: {
    editedGuide: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      file: null,
      // isUploaded: false
    }
  },
  methods: {
    updateFile(newFile) {
      this.file = newFile
      this.$emit('update:file', this.file)
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    formatSize(size) {
      const mb = size / (1024 * 1024)
      return `${mb.toFixed(2)} MB`
    },
  }
}
</script>

<style scoped>
.file-uploader {
  position: relative;
}
.file-uploader svg {
  display: block;
}
.file-uploader__file-input {
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
}
.file-uploader__button-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 44px;
  background-color: var(--white);
  border: 1px solid var(--reflex-bluet);
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--reflex-bluet);
  cursor: pointer;
}

.file-uploader__info {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding: 0 10px;
  background-color: var(--cotton-grey-f-5);
  border: 1px solid transparent;
  border-radius: 6px;
  transition: border 0.3s ease;
}
.file-uploader__info--error {
  border: 1px solid var(--scarlet-red);
}
.file-uploader__info-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: var(--white);
  border: 1px solid var(--menu-border);
  border-radius: 100%;
}
/* .file-uploader__info-loading-circle {
  width: 16px;
  height: 16px;
  transform: rotate(0);
  animation: circle 1.1s linear infinite;
}
.file-uploader__info-loading-circle svg {
  overflow: visible;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.file-uploader__info-loading-circle path {
  stroke: var(--Content-Tertiary);
  stroke-dasharray: 50;
  stroke-dashoffset: 100;
  animation: dash 1s linear infinite;
} */

/* .file-uploader__button-cancel, */
.file-uploader__button-reload {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.file-uploader__info-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--space-cadet);
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-uploader__info-name--done {
  color: var(--Content-Link);
}
.file-uploader__info-size {
  flex: 1 0 fit-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-text);
  text-wrap: nowrap;
  overflow: hidden;
}

/* @keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  100% {
    stroke-dasharray: 50;
    stroke-dashoffset: 0;
  }
} */
</style>