<template>
  <div class="guide-manager__page">
    <div class="guide-manager__header">
      <div class="guide-manager__header-left">
        <div class="guide-manager__header-text">
          Guide Manager
        </div>
      </div>
      <div class="guide-manager__header-right">
        <GlButton
          :icon-only="!isDesktop"
          pale-dark
          title="Create new guide"
          @click="showCreateModal"
        >
          <template #beforeTitle>
            <gl-icon
              class="mr-2"
              height="20"
              name="pp-add"
              width="20"
            />
          </template>
        </GlButton>
      </div>
    </div>
    <div class="guide-manager__search">
      <UserGuideSearch 
        :search-type="'manager'"
        @emitedData="changeSearch"
      />
    </div>
    <div
      v-if="getGmTable.length"
      class="guide-manager__content"
    >
      <GuideManagerTable
        :table-data="getGmTable"
        @changeStatus="changeStatus"
        @delete="showDeleteModal"
        @edit="showEditModal"
      />
    </div>
    <UserGuideEmpty
      v-else
      :error="error"
      :search="search"
      @reload="reloadRequest"
    />
    <div class="guide-manager__pagination">
      <GuideManagerPagination @paginationChange="paginationChange" />
    </div>
    <transition name="loading-fade">
      <UserGuideLoading v-if="loading" />
    </transition>

    <CreateGuideModal
      v-model="stateCreateGuideModal"
      :edited-guide="editedGuide"
      @close="stateCreateGuideModal = false"
      @createGuide="sendCreatedGuide"
      @updateGuide="updateGuide"
    />
    <DeleteGuideModal
      v-model="stateDeleteGuideModal"
      :guide-to-delete="guideToDelete"
      @close="stateDeleteGuideModal = false"
      @deleteGuide="deleteGuide"
    />
  </div>
</template>

<script>
// vuex
import { mapGetters, mapMutations, mapActions } from 'vuex'

// components
import GlButton from '@/components/gl-button'
import GlIcon from '@/components/gl-icon'
import UserGuideLoading from '@/pages/user-guide/components/UserGuideLoading.vue'
import UserGuideSearch from '@/pages/user-guide/components/UserGuideSearch.vue'
import UserGuideEmpty from '@/pages/user-guide/components/UserGuideEmpty.vue'
import GuideManagerPagination from '@/pages/guide-manager/components/GuideManagerPagination.vue'
import GuideManagerTable from '@/pages/guide-manager/components/GuideManagerTable.vue'

// modals
import CreateGuideModal from './modals/CreateGuideModal'
import DeleteGuideModal from './modals/DeleteGuideModal'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlButton,
    GlIcon,
    UserGuideLoading,
    UserGuideSearch,
    UserGuideEmpty,
    GuideManagerPagination,
    GuideManagerTable,
    CreateGuideModal,
    DeleteGuideModal,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      loading: false,
      search: '',
      error: false,
      stateCreateGuideModal: false,
      editedGuide: null,
      stateDeleteGuideModal: false,
      guideToDelete: null,
    }
  },
  computed: {
    ...mapGetters('userGuide', ['getGmTable', 'getGmPagination']),
  },
  mounted() {
    this.loadUserGuides()
  },
  methods: {
    ...mapActions({
      getUserGuides: 'userGuide/getUserGuides',
      addUserGuide: 'userGuide/addUserGuide',
      updateUserGuide: 'userGuide/updateUserGuide',
      deleteUserGuide: 'userGuide/deleteUserGuide',
    }),
    ...mapMutations('userGuide', ['SET_GM_TABLE', 'SET_GM_PAGINATION_TOTAL', 'SET_GM_PAGINATION_CURRENT_PAGE']),
    showCreateModal() {
      this.editedGuide = null
      this.stateCreateGuideModal = true
    },
    showEditModal(data) {
      this.editedGuide = data
      this.stateCreateGuideModal = true
    },
    showDeleteModal(item) {
      this.guideToDelete = item
      this.stateDeleteGuideModal = true
    },
    async loadUserGuides() {
      this.loading = true
      this.error = false

      await this.getUserGuides({ 
        name: this.search, 
        page: this.getGmPagination.currentPage, 
        limit: this.getGmPagination.perPage 
      })
        .then(({ data }) => {
          this.SET_GM_TABLE(data.items)
          this.SET_GM_PAGINATION_TOTAL(data.totalPages)
        })
        .catch(e => {
          this.$toasted.global.error({ message: e })
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeSearch({ search }) {
      this.search = search
      this.SET_GM_PAGINATION_CURRENT_PAGE(1)
      this.loadUserGuides()
    },
    paginationChange() {
      this.loadUserGuides()
    },
    async sendCreatedGuide(params) {
      this.stateCreateGuideModal = false
      this.loading = true

      this.addUserGuide(params)
        .then(({ data, success }) => {
          if(success) {
            this.loadUserGuides()
            this.$toasted.global.success({ message: 'New user guide added' })
          } else {
            this.loading = false
            this.$toasted.global.error({ message: data.message })
          }
        })
    },
    async deleteGuide(params) {
      this.stateDeleteGuideModal = false
      this.loading = true

      await this.deleteUserGuide(params)
        .then(({ data, success }) => {
          if(success) {
            this.loadUserGuides()
            this.$toasted.global.success({ message: 'User guide deleted' })
          } else {
            this.loading = false
            this.$toasted.global.error({ message: data.message })
          }
        })
    },
    async changeStatus(params) {
      this.loading = true

      this.updateUserGuide(params)
        .then(({ data, success }) => {
          if(success) {
            this.loadUserGuides()
            this.$toasted.global.success({ message: 'Status changed' })
          } else {
            this.loading = false
            this.$toasted.global.error({ message: data.message })
          }
        })
    },
    async updateGuide(params) {
      this.stateCreateGuideModal = false
      this.loading = true

      this.updateUserGuide(params)
        .then(({ data, success }) => {
          if(success) {
            this.loadUserGuides()
            this.$toasted.global.success({ message: 'User guide updated' })
          } else {
            this.loading = false
            this.$toasted.global.error({ message: data.message })
          }
        })
    },
    reloadRequest() {
      this.loadUserGuides()
    }
  }
}
</script>

<style scoped>
.guide-manager__page {
  display: flex;
  flex-direction: column;
  padding: 8px 24px 24px 24px;
  position: relative;
  overflow: hidden;
}
.guide-manager__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.guide-manager__header-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--space-cadet);
}
.guide-manager__header::v-deep .gl-button {
  height: 40px;
  text-transform: none;
}
.guide-manager__search::v-deep .gl-button {
  width: 104px !important;
  min-width: 104px;
  color: var(--reflex-bluet);
  background-color: var(--white);
  border-width: 2px;
  font-weight: 600;
  text-transform: none;
}

.guide-manager__search {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 16px 24px;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  border-bottom: none;
  border-radius: 16px 16px 0 0;
  position: relative;
}
.guide-manager__search::v-deep .user-guide__search-wrap {
  width: 656px;
}
.guide-manager__search::v-deep .gl-search-box {
  gap: 16px;
}
.guide-manager__search::v-deep .gl-search-box .gl-input__input {
  min-width: 450px !important;
  padding-left: 16px;
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 8px;
}
.guide-manager__search::v-deep .gl-search-box .gl-button {
  width: 140px;
}
.guide-manager__search::v-deep .user-guide__dropdown,
.guide-manager__search::v-deep .user-guide__dropdown-empty {
    width: calc(100% - 120px);
}
.guide-manager__content {
  flex: 1 1 auto;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  overflow: auto;
}
.guide-manager__content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.guide-manager__pagination {
  padding: 16px 24px;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  border-top: none;
  border-radius: 0 0 16px 16px;
}
.pp-pagination-table {
  padding: 0;
}


/* My cases modals */
.pp-modal::v-deep .gl-modal__container {
  padding: 24px;
}
.pp-modal::v-deep .gl-modal__title {
  margin-bottom: 16px;
  text-transform: none !important;
}
.pp-modal::v-deep .form-content-wrapper {
  margin-bottom: 48px;
}
.pp-modal::v-deep .gl-form__button {
  width: calc(50% - 12px);
}
.pp-modal::v-deep .gl-form__button.mr-2 {
  margin-right: 0 !important;
}
.pp-modal::v-deep .gl-input__input {
  border-radius: 6px;
  font-size: 14px;
}
.pp-modal::v-deep .gl-button {
  text-transform: none;
}


@media screen and (max-width: 1439px) {
  
}
@media screen and (max-width: 1199px) {
  .guide-manager__search::v-deep .gl-search-box {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .guide-manager__page {
    padding: 54px 8px 0 8px;
  }
  .guide-manager__search {
    align-items: start;
    margin: 0 -8px 8px -8px;
    padding: 16px;
    border-radius: 0;
  }
  .guide-manager__search-wrap {
    width: 100%;
  }
  .guide-manager__search::v-deep .gl-search-box .gl-input__input {
    min-width: 100% !important;
    padding-left: 10px;
  }
  .guide-manager__content {
    border-radius: 8px 8px 0 0;
  }
  .guide-manager__pagination {
    margin: 0 -8px;
    padding: 10px 16px;
    border-radius: 0;
  }
  .guide-manager__pagination .pp-pagination-table {
    flex-direction: row;
    row-gap: 0;
  }
}


.loading-fade-enter-active,
.loading-fade-leave-active {
  transition: opacity 0.2s ease;
}
.loading-fade-enter,
.loading-fade-leave-to {
  opacity: 0;
}
</style>