<template>
  <div class="user-guide__empty">
    <div
      v-if="error"
      class="user-guide__empty-wrap user-guide__empty-wrap--error"
    >
      <div class="user-guide__error-header">
        Failed to load content 
      </div>
      <div class="user-guide__error-text">
        Please try again later
      </div>
      <div
        class="user-guide__error-button"
        @click="$emit('reload')"
      >
        <span>Reload</span>
      </div>
    </div>
    <div
      v-else-if="search"
      class="user-guide__empty-wrap"
    >
      <div class="user-guide__empty-text">
        Your search - <span>{{ search }}</span> - didn’t match any user guides 
      </div>
      <div class="user-guide__empty-suggestions">
        <div class="user-guide__empty-suggestions-header">
          Suggestions:
        </div>
        <div class="user-guide__empty-suggestions-item">
          Make sure all words are spelled correctly
        </div>
        <div class="user-guide__empty-suggestions-item">
          Try using different keywords
        </div>
        <div class="user-guide__empty-suggestions-item">
          Try using more general keywords
        </div>
      </div>
    </div>
    <div
      v-else
      class="user-guide__empty-wrap"
    >
      <div class="user-guide__empty-text">
        No user guides have been added yet
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>
.user-guide__empty {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 40px;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  overflow-y: auto;
}
.user-guide__empty::-webkit-scrollbar {
  width: 6px;
}
.user-guide__empty-text {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
.user-guide__empty-text span {
  font-weight: 600;
}
.user-guide__empty-suggestions-header {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--dark-grey-6-e);
}
.user-guide__empty-suggestions-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--space-cadet);
}
.user-guide__empty-suggestions-item:before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background-color: var(--space-cadet);
  border-radius: 100%;
}

.user-guide__error-header,
.user-guide__error-text {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}
.user-guide__error-text {
  margin-bottom: 32px;
  font-weight: 400;
}
.user-guide__error-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 248px;
  height: 48px;
  background-color: var(--reflex-bluet);
  border-radius: 6px;
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .user-guide__empty {
    align-items: start;
    padding: 24px 16px;
  }

  .user-guide__empty-wrap--error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .user-guide__error-button {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>