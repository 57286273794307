<template>
  <div class="pp-pagination-table">
    <div class="pp-pagination-table__left" />
    <div
      v-if="getGmTable.length && getGmPagination.totalPages > 1"
      class="pp-pagination-table__right"
    >
      <o-pagination
        :current="getGmPagination.currentPage"
        :per-page="getGmPagination.perPage"
        :range-after="0"
        :range-before="0"
        :total="getGmPagination.totalPages * getGmPagination.perPage"
        @change="pageChange"
      >
        <template #default="props">
          <div
            v-if="props.page.isCurrent"
            class="pp-pagination-table__pages"
          >
            {{ `${props.page.number} of ${getGmPagination.totalPages}` }}
          </div>
        </template>
        <template #previous="props">
          <div class="pp-pagination-table__button-prev">
            <o-pagination-button
              :page="props.page"
            >
              <gl-menu-item
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
            <div class="pp-pagination-table__current">
              {{ props.page.number + 1 }}
            </div>
          </div>
        </template>
        <template #next="props">
          <div class="pp-pagination-table__button-next">
            <o-pagination-button
              :page="props.page"
            >
              <gl-menu-item
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </div>
        </template>
      </o-pagination>
    </div>
  </div>  
</template>

<script>
// vuex
import { mapGetters, mapMutations } from 'vuex'

// components
import GlMenuItem from "@/components/gl-menu-item.vue"

export default {
  components: {
    GlMenuItem
  },
  computed: {
    ...mapGetters('userGuide', ['getGmTable', 'getGmPagination']),
  },
  methods: {
    ...mapMutations('userGuide', ['SET_GM_PAGINATION_CURRENT_PAGE']),
    pageChange(currentPage) {
      this.SET_GM_PAGINATION_CURRENT_PAGE(currentPage)
      this.$emit('paginationChange')
    }
  }
}
</script>
