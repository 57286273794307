<template>
  <gl-modal
    v-bind="$attrs"
    class="pp-modal create-modal"
    :disabled-submit="submitDisable"
    full-buttons
    submit-title="Create"
    title="Creating new guide"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="create-modal__form">
      <div class="create-modal__form-item">
        <UploadFileInput
          :edited-guide="{ editedFileName, editedFileSize }"
          :errors="validateGuideForm.file"
          @update:file="handleFileUpdate"
        />
        <div
          class="create-modal__form-item-error"
          :class="{ 'create-modal__form-item-error--show' : validateGuideForm.file }"
        >
          <div class="create-modal__form-item-error-wrap">
            {{ validateGuideForm.file }}
          </div>
        </div>
      </div>
      <div class="create-modal__form-item">
        <div class="create-modal__form-item-title">
          TITLE
        </div>
        <div
          class="create-modal__form-item-input"
          :class="{ 'create-modal__form-item-input--error' : validateGuideForm.name }"
        >
          <input
            v-model="name"
            placeholder="Please enter a title between 3 and 100 characters"
            type="text"
          >
        </div>
        <div
          class="create-modal__form-item-error"
          :class="{ 'create-modal__form-item-error--show' : validateGuideForm.name }"
        >
          <div class="create-modal__form-item-error-wrap">
            {{ validateGuideForm.name }}
          </div>
        </div>
      </div>
      <div class="create-modal__form-item">
        <div class="create-modal__form-item-title">
          Description
        </div>
        <div
          class="create-modal__form-item-input"
          :class="{ 'create-modal__form-item-input--error' : validateGuideForm.description }"
        >
          <textarea
            v-model="description"
            placeholder="Please enter a description (optional, max 300 characters)"
          />
        </div>
        <div
          class="create-modal__form-item-error"
          :class="{ 'create-modal__form-item-error--show' : validateGuideForm.description }"
        >
          <div class="create-modal__form-item-error-wrap">
            {{ validateGuideForm.description }}
          </div>
        </div>
      </div>
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import UploadFileInput from '../components/GuideManagerUploadFile.vue'

export default {
  components: {
    GlModal,
    UploadFileInput,
  },
  props: {
    editedGuide: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      file: null,
      name: null,
      description: null,
      editedFileName: null,
      editedFileSize: null,
    }
  },
  computed: {
    validateGuideForm() {
      const errors = {}

      if (!this.file && !this.editedGuide) {
        errors.file = "A PDF file is required"
      } else if (this.file?.size > 50 * 1024 * 1024) {
        errors.file = "File size exceeds the 50MB limit. Please upload a smaller file."
      } else if (this.file?.name && !this.file?.name.endsWith('.pdf')) {
        errors.file = "Invalid file type. Only PDF files are allowed."
      }

      if (this.name && !this.name.trim()) {
        errors.name = "Title is required"
      } else if (this.name && this.name.trim().length > 0 && this.name.trim().length < 3) {
        errors.name = "Title is required and must be at least 3 characters long"
      } else if (this.name && this.name.length > 100) {
        errors.name = "Title must not exceed 100 characters"
      } else if (this.name && this.name.trim() && !/^[a-zA-Z0-9\s.,!?'"-]+$/.test(this.name)) {
        errors.name = "Title contains invalid characters"
      }

      if (this.description && this.description.trim().length > 300) {
        errors.description = "Description is too long. Maximum 300 characters allowed."
      } else if (this.description && this.description.trim() && !/^[a-zA-Z0-9\s.,!?'"-]+$/.test(this.description)) {
        errors.description = "Description contains invalid characters"
      }

      return {
        file: errors.file,
        name: errors.name,
        description: errors.description
      }
    },
    submitDisable() {
      return !Object.values(this.validateGuideForm).every(value => !value)
    }
  },
  watch: {
    '$attrs.value'() {
      this.file = null
      this.name = null
      this.description = null
      this.editedFileName = null
      this.editedFileSize = null
    },
    editedGuide(guide) {
      this.name = guide?.name
      this.description = guide?.description
      this.editedFileName = guide?.fileName
      this.editedFileSize = guide?.fileSize
    }
  },
  methods: {
    handleFileUpdate(file) {
      this.file = file
    },
    onSubmit() {
      const formData = new FormData()

      if (this.editedGuide?._id) {
        formData.append('_id', this.editedGuide._id)

        if (this.name) {
          formData.append('name', this.name)
        }
        if (this.description) {
          formData.append('description', this.description)
        }
        if (this.file) {
          formData.append('file', this.file)
        }

        this.$emit('updateGuide', formData)
      } else {
        formData.append('name', this.name)
        formData.append('description', this.description)
        formData.append('enabled', false)
        formData.append('file', this.file)

        this.$emit('createGuide', formData)
      }
    },
  }
}
</script>

<style scoped>
.create-modal::v-deep .form-content-wrapper {
  margin-bottom: 32px !important;
}
.create-modal::v-deep .gl-modal__title {
  margin-bottom: 24px !important;
}
.create-modal__form input,
.create-modal__form textarea {
  background-color: var(--cotton-grey-f-5);
  border: 1px solid transparent;
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--space-cadet);
  outline: none;
  transition: border 0.3s ease;
}
.create-modal__form input::placeholder,
.create-modal__form textarea::placeholder {
  color: var(--placeholder);
}
.create-modal__form input {
  width: 100%;
  height: 32px;
  padding: 0 10px;
}
.create-modal__form textarea {
  width: 100%;
  height: 86px;
  padding: 6px 10px;
  resize: none;
}
.create-modal__form textarea::-webkit-scrollbar {
  width: 6px;
}
.create-modal__form-item + .create-modal__form-item {
  margin-top: 16px;
}
.create-modal__form-item-title {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-grey-6-e);
  text-transform: uppercase;
}
.create-modal__form-item-input--error input,
.create-modal__form-item-input--error textarea {
  border: 1px solid var(--scarlet-red);
}
.create-modal__form-item-error {
  display: grid;
  grid-template-rows: 0fr;
  grid-template-columns: 1fr;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: var(--scarlet-red);
  transition: grid-template-rows 0.3s ease;
}
.create-modal__form-item-error-wrap {
  padding-top: 8px;
  overflow: hidden;
}
.create-modal__form-item-error--show {
  grid-template-rows: 1fr;
}
</style>