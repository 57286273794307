<template>
  <gl-modal
    v-bind="$attrs"
    class="pp-modal delete-modal"
    full-buttons
    submit-title="Delete guide"
    title="Delete the guide?"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="delete-modal__text">
      Deleting the guide <span>“{{ guideToDelete?.name }}”</span> will permanently remove it from User Guide Center manager 
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  props: {
    guideToDelete: {
      type: Object,
      default: () => {},
    }
  },
  methods: {
    onSubmit() {
      this.$emit('deleteGuide', { _id: this.guideToDelete._id })
    },
  }
}
</script>

<style scoped>
.delete-modal::v-deep .form-content-wrapper {
  margin-bottom: 32px !important;
}
.delete-modal::v-deep .gl-modal__title {
  margin-bottom: 24px !important;
}
.delete-modal__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.delete-modal__text span {
  font-weight: 700;
}
</style>