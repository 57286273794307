<template>
  <div
    v-click-outside="outsideClick"
    class="user-guide__search-wrap"
    :class="{ 
      'user-guide__search-wrap--open' : dropdownFlag,
      'user-guide__search-wrap--empty' : emptyFlag
    }"
  >
    <gl-search-box
      ref="searchComponent"
      v-model="search"
      button-text="Search"
      dark-clear
      :disabled="!isSearchUpToThree"
      grey
      :height="`${searchType == 'manager' ? '36px' : '48px'}`"
      :independent="isMobile ? true : false"
      :min-width="isMobile ? '150px' : '250px'"
      placeholder="Search by keywords"
      @clear="clearSearching"
      @input="checkForSpaces"
      @inputFocusIn="inputFocusIn"
      @search="loadData"
    />
    <div class="user-guide__dropdown">
      <div class="user-guide__dropdown-wrap">
        <div
          v-for="autocompleteItem in autocompleteResult"
          :key="autocompleteItem._id"
          class="user-guide__dropdown-item"
          @click="updateSelected(autocompleteItem)"
        >
          <div class="user-guide__dropdown-item-name">
            {{ autocompleteItem.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="user-guide__dropdown-empty">
      <div>Your search - <span>{{ search }}</span> - didn’t match any user guides </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions } from 'vuex'

//Component
import GlSearchBox from '@/components/gl-search-box'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

// Directives
import vClickOutside from 'v-click-outside'

// Libs
import _ from 'lodash'

export default {
  name: 'UserGuideSearch',
  components: {
    GlSearchBox,
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [deviceWidthMixin],
  props: {
    searchType: {
      type: String,
      default: 'user',
    }
  },
  data() {
    return {
      loading: false,
      search: '',
      autocompleteResult: [],
      emptyFlag: false,
      dropdownFlag: true,
      afterLoadDropdownFlag: true,
      loadAutocompleteDebounced: _.debounce(function() {
        this.loadAutocomplete()
      }, 500)
    }
  },
  computed: {
    isSearchUpToThree() {
      return this.search.length >= 3
    }
  },
  methods: {
    ...mapActions({
      getUserGuides: 'userGuide/getUserGuides',
    }),
    dropdownState(result = false, empty = false) {
      this.dropdownFlag = result
      this.emptyFlag = empty
    },
    outsideClick() {
      this.dropdownState()
    },
    clearSearching() {
      this.search = ''
      this.dropdownState()
      this.loadData()
    },
    inputFocusIn(searchData) {
      if(searchData.trim()) {
        this.loadAutocompleteDebounced()
      }
    },
    loadData() {
      this.dropdownState()
      this.$emit('emitedData', {
        search: this.search,
      })
    },
    updateSelected({ name }) {
      this.afterLoadDropdownFlag = false
      this.search = name
      this.loadData()
    },
    checkForSpaces(input) {
      if (!input.trim()) {
        this.clearSearching()
        this.$refs.searchComponent.clearValue()
      } else {
        if (this.isSearchUpToThree) {
          this.loadAutocompleteDebounced()
        } else {
          this.dropdownState()
        }
      }
    },
    async loadAutocomplete() {
      if(this.afterLoadDropdownFlag) {
        this.loading = true

        // await this.getEntityList({
        //   searchAutocomplete: this.search,
        // })
        //   .then(({ items, count }) => {
        //     if(count) {
        //       this.dropdownState(true, false)
        //       this.autocompleteResult = items
        //     } else {
        //       this.dropdownState(false, true)
        //     }
        //   })
        //   .catch(e => {
        //     this.$toasted.global.error({ message: e })
        //   })
        //   .finally(() => {
        //     this.loading = false
        //   })

        await this.getUserGuides({ name: this.search, page: 1, limit: 100 })
          .then(({ data }) => {
            if(data.totalPages) {
              this.dropdownState(true, false)
              this.autocompleteResult = data.items
            } else {
              this.dropdownState(false, true)
            }
          })
          .catch(e => {
            this.$toasted.global.error({ message: e })
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.afterLoadDropdownFlag = true
      }
    }
  }
}
</script>

<style scoped>
.user-guide__search-wrap {
  position: relative;
  z-index: 5;
}
.user-guide__dropdown,
.user-guide__dropdown-empty {
  opacity: 0;
  display: grid;
  grid-template-rows: 1fr;
  width: calc(100% - 156px);
  max-height: 229px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 10px 21px 0px #0000001A, 0px 39px 39px 0px #00000017, 0px 87px 52px 0px #0000000D, 0px 154px 62px 0px #00000003, 0px 241px 67px 0px #00000000;
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  transform: translate(0, -10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  overflow: hidden;
}
.user-guide__dropdown-empty {
  padding: 12px;
  font-size: 14px;
  color: var(--dark-grey);
  text-align: center;
}
.user-guide__dropdown-empty span {
  font-weight: 600;
}
.user-guide__search-wrap--open .user-guide__dropdown,
.user-guide__search-wrap--empty .user-guide__dropdown-empty {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}
.user-guide__dropdown-wrap {
  overflow: auto;
}
.user-guide__dropdown-wrap::-webkit-scrollbar {
  width: 6px;
}
.user-guide__dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.user-guide__dropdown-item + .user-guide__dropdown-item {
  margin-top: 6px;
}
.user-guide__dropdown-item-name {
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-guide__dropdown-item:hover {
  background-color: var(--cotton-grey-f-5);
}

@media (max-width: 767px) {
  .user-guide__dropdown,
  .user-guide__dropdown-empty {
    width: 100%;
  }
  .user-guide__search-wrap .gl-search-box {
    overflow: hidden;
  }
}
</style>