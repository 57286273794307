<template>
  <div class="guide-manager__table">
    <o-table
      :data="tableData"
      :mobile-cards="false"
    >
      <o-table-column
        v-slot="props"
        field="name"
        label="Name"
        sortable
      >
        <div class="guide-manager__table-name">
          {{ props.row.name }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="description"
        label="Description"
        sortable
      >
        <div class="guide-manager__table-description">
          {{ props.row.description }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="date"
        label="Date of last update"
        sortable
      >
        <div class="guide-manager__table-date">
          {{ props.row.date }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="status"
        label="Status"
        sortable
      >
        <div 
          class="guide-manager__table-status"
          @click.prevent="changeStatus(props.row._id)"
        >
          <o-switch
            v-model="statusesOfGuides[props.row._id]"
          />
          <div class="guide-manager__table-status-text">
            <div
              class="guide-manager__table-status-text-active"
              :class="{ 'visible' : statusesOfGuides[props.row._id] }"
            >
              Active
            </div>
            <div
              class="guide-manager__table-status-text-unactive"
              :class="{ 'visible' : !statusesOfGuides[props.row._id] }"
            >
              Unactive
            </div>
          </div>
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        label="Actions"
      >
        <div class="guide-manager__table-actions">
          <gl-menu-item
            v-popover:tooltip.top="'Edit'"
            fullwidth
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            label=""
            warn
            @click.prevent="$emit('edit', props.row)"
          />
          <gl-menu-item
            v-popover:tooltip.top="'Delete'"
            fullwidth
            icon="delete"
            :icon-height="24"
            :icon-width="24"
            label=""
            warn
            @click.prevent="$emit('delete', props.row)"
          />
        </div>
      </o-table-column>
    </o-table>
  </div>
</template>

<script>
// Components
import GlMenuItem from '@/components/gl-menu-item.vue'

export default {
  name: 'GuideManagerTable',
  components: {
    GlMenuItem,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    tableData: {
      immediate: true,
      handler(newData) {
        this.resetStatuses(newData)
      },
    },
  },
  data() {
    return {
      statusesOfGuides: {},
    }
  },
  computed: {
    
  },
  methods: {
    resetStatuses(newData) {
      this.statusesOfGuides = {}

      newData.forEach(item => {
        this.$set(this.statusesOfGuides, item._id, item.enabled)
      })
    },
    changeStatus(_id) {
      this.statusesOfGuides[_id] = !this.statusesOfGuides[_id]

      const formData = new FormData()
      formData.append('_id', _id)
      formData.append('enabled', this.statusesOfGuides[_id])

      this.$emit('changeStatus', formData)
    }
  },
}
</script>

<style>
.guide-manager__table .o-table__th,
.guide-manager__table .o-table__th-current-sort,
.guide-manager__table .o-table__td {
  border-bottom: 1px solid var(--cotton-grey-f-5) !important;
}
.guide-manager__table .o-table__th {
  padding: 8px 16px 8px 0 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: var(--dark-grey-6-e);
}
.guide-manager__table .o-table__th:first-child {
  padding-left: 24px !important;
}
.guide-manager__table .o-table__th:last-child {
  padding: 8px 48px 8px 0 !important;
  text-align: right;
}
.guide-manager__table .o-table__td {
  padding: 4px 16px 4px 0 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--space-cadet);
}
.guide-manager__table .o-table__td:first-child {
  padding-left: 24px !important;
}
.guide-manager__table .o-table__td:last-child {
  padding-right: 0 !important;
}
.guide-manager__table .guide-manager__table-description {
  font-weight: 400;
  color: var(--dark-grey-6-e);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.guide-manager__table-status {
  display: flex;
  gap: 8px;
  width: 108px;
}
.guide-manager__table-status .o-switch {
  width: 36px;
}
.guide-manager__table-status-text {
  flex: 1 0 auto;
  height: 22px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.guide-manager__table-status-text-active,
.guide-manager__table-status-text-unactive {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0, 100%);
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.guide-manager__table-status-text-active {
  color: var(--reflex-bluet);
}
.guide-manager__table-status-text-unactive {
  color: var(--dark-grey-6-e);
}
.guide-manager__table-status-text-active.visible,
.guide-manager__table-status-text-unactive.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.guide-manager__table-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
  padding-right: 48px;
}
.guide-manager__table-actions .gl-menu-item {
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 0;
  background-color: var(--cotton-grey-f-5);
  border-radius: 4px;
}


.guide-manager__table .o-table__th:first-child,
.guide-manager__table .o-table__td:first-child {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}
.guide-manager__table .o-table__th:nth-child(2),
.guide-manager__table .o-table__td:nth-child(2) {
  max-width: 300px;
  min-width: 300px;
}
.guide-manager__table .o-table__th:nth-child(3),
.guide-manager__table .o-table__td:nth-child(3),
.guide-manager__table .o-table__th:nth-child(4),
.guide-manager__table .o-table__td:nth-child(4) {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
}
.guide-manager__table .o-table__th:last-child,
.guide-manager__table .o-table__td:last-child {
  width: 122px;
  max-width: 122px;
  min-width: 122px;
}
</style>